html,
body {
  font-size: 62.5%;
  font-family: "Proxima_Nova", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: linear-gradient(
    to top,
    #1b275d,
    #13204b,
    #0e1a39,
    #0b1228,
    #020618
  ); */

  min-height: 100vh;
  background-size: cover;
  /* background: linear-gradient(180deg, #020618 0%, #1B275D 100%); */
  background-color: var(--background, #222);
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

@import url('https://fonts.googleapis.com/css2?family=Michroma&display=swap');

@font-face {
  font-family: "Proxima_Nova";
  src: url("./Assets/fonts/ProximaNova-Regular.eot");
  src: url("./Assets/fonts/ProximaNova-Regular.eot?#iefix") format("embedded-opentype"),
    url("./Assets/fonts/ProximaNova-Regular.woff2") format("woff2"),
    url("./Assets/fonts/ProximaNova-Regular.woff") format("woff"),
    url("./Assets/fonts/ProximaNova-Regular.ttf") format("truetype"),
    url("./Assets/fonts/ProximaNova-Regular.svg#ProximaNova-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "EB_Garamond";
  src: url("./Assets/fonts/EBGaramond-Regular.eot");
  src: url("./Assets/fonts/EBGaramond-Regular.eot?#iefix") format("embedded-opentype"),
    url("./Assets/fonts/EBGaramond-Regular.woff2") format("woff2"),
    url("./Assets/fonts/EBGaramond-Regular.woff") format("woff"),
    url("./Assets/fonts/EBGaramond-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.main-container {
  max-width: 1440px;
  margin: 0 auto;
}

.title {
  font-family: "EB_Garamond" !important;
  font-size: 3.2rem !important;
  color: #fff;
  font-weight: 400 !important;
  width: 70%;
  margin: 4rem auto;
  border-top: 1px solid;
  border-bottom: 1px solid;
  margin: 50px 0 !important;
}

.title span {
  font-style: italic;
}

.video-container {
  max-width: 734px;
  padding: 0 1rem;
  margin: 4rem auto 0;
  text-align: -webkit-center;
}

.bottom-section {
  max-width: 950px;
  padding: 0 1rem;
  margin: 4rem auto 0;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%;
  /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.react-player div iframe {
  border-radius: 20px;
}

.react-player-youtube div iframe {
  border-radius: 20px;
}

.react-player-youtube {
  margin: 40px auto;
  max-width: 733px;
  max-height: 412px;
  height: auto;
  width: 100%;
}

.react-player-youtube.div+div {
  min-height: 250px !important;
}

.react-player__shadow {
  border: 1px solid #FFF;
  width: 91.815px !important;
  height: 91.826px !important;
  background: radial-gradient(rgba(0, 0, 0, 0.3), rgb(255 255 255 / 50%) 60%) !important;
}

@media screen and (max-width: 700px) {

  .react-player-youtube,
  .image-thumbnail {
    width: 100% !important;
    height: auto !important;
  }

  .react-player__shadow {
    width: 64px !important;
    height: 64px !important;
  }
}

@media screen and (max-width: 700px) and (min-width: 600px) {
  .react-player-youtube {
    height: 380px !important;
  }
}

@media screen and (max-width: 600px) and (min-width: 500px) {
  .react-player-youtube {
    height: 320px !important;
  }
}

@media screen and (max-width: 500px) {
  .react-player-youtube {
    height: 250px !important;
  }
}

.insructions {
  font-family: "Proxima_Nova", serif;
  text-align: left;
  font-size: 2.4rem;
  color: #fff;
  text-align: center;
}

.insructions h4 {
  margin: 4rem 0;
  text-align: center;
  font-size: 30px;
  font-weight: 400;
  line-height: 37px;
  letter-spacing: 0.2em;
  text-align: center;
  color: var(--primary-pink, #FF7000);
}

.insructions ul {
  margin: 0;
  padding: 0;
  margin-bottom: 50px;
  /* padding-left: 35px; */
  /* list-style: inside; */
}

.textcontentcentralized{
  padding: 0 13% !important;
}

.insructions li {
  font-family: "Proxima_Nova", serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 34px;
  text-align: left;
}

p.record-voice {
  text-align: center;
  font-size: 30px;
  font-weight: 400;
  line-height: 37px;
  letter-spacing: 0.2em;
  text-align: center;
  font-family: "Proxima_Nova", serif;
  margin: 50px 0 50px;
  color: var(--primary-pink, #EBE900);

}

p.record-avl {
  text-align: center;
  font-size: 30px;
  font-weight: 400;
  line-height: 37px;
  letter-spacing: 0.2em;
  text-align: center;
  font-family: "Proxima_Nova", serif;
  margin: 50px 0 50px;
  color: var(--primary-pink, #5DFF00);

}

.flex-box {
  display: flex;
  flex-wrap: wrap;
  margin: 1.2rem 0;
  position: relative;
}

.flex-box .block1 {
  margin: 0 8px;
}

.flex-box .b2 {
  margin: 0 8px;
}

.flex-box .block3 {
  flex: 1;
}

.audio-wrp-box {
  padding: 0 6vw;
  margin-bottom: 50px;
}

.hideAudioMic {
  display: none
}

.seek-bar {
  width: calc(100% - 115px);
  border-radius: 5rem;
  border: 1px solid var(--cp-blue, #007DFF);
  padding-right: 90px !important;
  padding-left: 20px !important;
}

.flex-box .b1 {
  flex: 1;
}

.flex-box .b2 {
  flex: 2;
}

.dot-bdr {
  border-top: 2px dashed #B3B3B3;
  /* border: 1px solid #B3B3B3; */
  display: block;
  cursor: pointer;
  padding: 15px;
  position: relative;
}

.dot-bdr-down {
  position: absolute;
  left: -24px;
  top: -15px;
  font-size: 30px !important;
  color: #007DFF;
}

.dot-bdr-up {
  position: absolute;
  left: -24px;
  top: -17px;
  font-size: 30px !important;
  color: #007DFF;
}

select {
  border: 1px solid var(--cp-blue, #007DFF);
  background-color: transparent;
}

.select-dropdown {
  border: 1px solid var(--cp-blue, #007DFF);
}

.css-1ixrco2-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root .MuiOutlinedInput-notchedOutline,
.css-1r95iv8 .MuiOutlinedInput-notchedOutline {
  border: none;
}

.rad {
  /* border-radius: 2rem !important; */
  /* width: 88%; */
  border-radius: 5rem;
  height: 4.8rem;
  border: 1px solid var(--cp-blue, #007DFF);
  background-color: transparent !important;
  color: #FFF;
  font-size: 2rem;
}

.redis-btn {
  border-radius: 5rem !important;
}

.table-class {
  max-width: 350px;
  /* overflow-x: auto; */
  margin: 0 auto;
  width: 100%;
  /* max-height: 1000px; */
  /* overflow-y: auto; */
  /* overflow-x: hidden; */
  /* padding: 15px; */

}

.grid-box {
  display: grid;
  grid-template-columns: 161px 1fr 150px;
  gap: 8px;
}

.input-width {
  padding: 0 2rem;
}

.input-width:focus-visible {
  outline: none
}

.input-width::placeholder {
  color: #898989;
  font-family: "Proxima_Nova", serif !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.btn-submit {
  font-family: "Proxima_Nova" !important;
  background: #EBE900 !important;
  font-size: 2rem !important;
  color: #151718 !important;
  width: 100% !important;
  text-transform: uppercase !important;
  /* margin-left: -5px !important; */
  cursor: pointer;
}

.plus-btn,
.play-btn {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 1px solid #ff6dd3;
  position: relative;
}

.plus-btn img,
.play-btn img {
  color: #fff;
  width: auto;
  height: auto;
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  text-align: center;
  left: 33%;
}

.separator {
  margin-top: 1.5rem;
  width: 100%;
  height: 1px;
  background: #B3B3B3;
}

::placeholder {
  text-transform: uppercase;
  color: #fff;
}

::-ms-input-placeholder {
  text-transform: uppercase;
  color: #fff;
}

/* Video customize css */
.video-react,
.video-react .video-react-poster {
  background-color: transparent !important;
}

.video-react .video-react-big-play-button {
  width: 9.1rem !important;
  height: 9.1rem !important;
  border-radius: 50% !important;
  background-color: transparent !important;
}

.video-react .video-react-control:before,
.video-react .video-react-big-play-button:before {
  position: initial !important;
}

.css-qbdosj-Input {
  margin: 0 !important;
  padding: 0 !important;
}

.css-13cymwt-control,
.css-Imndick5-menu,
.css-13cymwt-control:hover,
.css-b62m3t-container,
.css-tj5bde-Svg {
  background: transparent !important;
  border-radius: 5rem !important;
  border-color: #9a50ff !important;
  box-shadow: none !important;
  min-height: 3.2rem !important;
}

.css-1fdsijx-ValueContainer {
  border-radius: 20px !important;
}

.leaderboard-h {
  width: 100%;
  border-radius: 5rem;
  border: 1px solid var(--cp-blue, #007DFF);
  height: 4.8rem;
  background-color: rgba(0, 125, 255, 0.40);
  width: 350px;

}

.leaderboard-sb {
  width: 100%;
  border-radius: 5rem;
  border: 1px solid var(--cp-blue, #007DFF);
  height: 4.8rem;
  margin: 1.4rem 0;
  width: 350px;
}

.leaderboard-h .heading,
.leaderboard-sb .heading {
  flex-grow: 1;
  padding: 0 3rem;
}

.heading1 {
  padding: 0 0px 0 30px;
  width: 80px;
  text-align: right;
}

.heading1 span,
.heading2 span {
  float: right;
  padding-left: 16px;
}

.heading2 {
  width: 33%;
  padding: 0 30px 0 15px;
}

.uploadFiles {
  /* background: hsl(265, 63%, 66%); */
  height: 60vh;
  /* width: 490px; */
  /* border: 1px solid 5px; */
  /* opacity: 0.2; */
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 8px;
  /* border: 1px dashed #9A50FF; */
  /* background: #D9D9FF; */
  cursor: pointer;
  border: 1px dashed var(--cp-blue, #007DFF);
  background: rgba(217, 217, 255, 0.40);

}

.uploadText {
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  text-align: center;
  width: 100%;
  height: auto;
  margin: auto;
  /* padding: 25% 0 0 0; */
}

.choosefIles {
  text-align: center;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn {
  border: 1px solid gray;
  /* color: #000; */
  background: var(--primary-pink, #FF6DD3);
  padding: 8px 20px;
  border-radius: 4rem;
  /* font-size: 1.6rem; */
  /* font-weight: bold; */
  cursor: pointer;

  color: #151718;
  text-align: center;
  font-family: "Proxima_Nova", serif !important;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

@media only screen and (max-width: 760px) {
  .seek-bar {
    margin: 1.2rem 0;
    height: 50px;
    width: 100% !important;
  }
}

/* joy progress bar  */
.css-1nv95zm-JoyCircularProgress-root {
  --CircularProgress-trackColor: var(--joy-palette-primary-solidBg, var(--joy-palette-primary-500, #EBE900)) !important;
  --CircularProgress-progressColor: var(--joy-palette-common-white, #007DFF) !important;
  --_track-thickness: var(--CircularProgress-trackThickness, var(--CircularProgress-thickness, 3px)) !important;
  --_progress-thickness: var(--CircularProgress-progressThickness, var(--CircularProgress-thickness, 3px)) !important;
  --_root-size: var(--CircularProgress-size, 48px) !important;
  --CircularProgress-size: 48px !important;
}

.joy_progress {
  --CircularProgress-trackColor: var(--joy-palette-primary-solidBg, var(--joy-palette-primary-500, #EBE900)) !important;
  --CircularProgress-progressColor: var(--joy-palette-common-white, #007DFF) !important;
  --_track-thickness: var(--CircularProgress-trackThickness, var(--CircularProgress-thickness, 3px)) !important;
  --_progress-thickness: var(--CircularProgress-progressThickness, var(--CircularProgress-thickness, 3px)) !important;
  --_root-size: var(--CircularProgress-size, 48px) !important;
  --CircularProgress-size: 48px !important;
}

.eleps {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.block1 {
  position: relative;
  /* width: calc(100% - 190px); */
}

.aud-time {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 10;
  font-family: "Proxima_Nova", serif;
  font-size: 18px;
  font-weight: 400;
  display: none;
}

.css-1us30cw-JoyIconButton-root:hover,
.css-1uofmu9-JoyIconButton-root:hover {
  background-color: #783E66 !important;
}

.css-vdulgp-JoyIconButton-root:hover,
.css-16asa00-JoyIconButton-root:hover,
.css-q7t84n-JoyIconButton-root:hover {
  background-color: #783E66 !important;
}

.privacy-title {
  color: #151718 !important;
  font-family: "Proxima_Nova", serif !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  text-transform: uppercase !important;
  text-align: center !important;
  margin-top: 20px !important;
}

.text-layout-privacy {
  height: 311px;
  flex-shrink: 0;
  border-radius: 8px;
  background: rgba(217, 217, 255, 0.40);
  padding: 20px 32px 6px 25px;
  overflow-y: auto;
}

#modal-desc {
  padding: 24px;
}

.italic {
  color: #F7F8FA;
  font-family: EB Garamond;
  font-size: 32px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}

.text-layout-privacy p {
  color: #222;
  font-family: "Proxima_Nova", serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.outlined-button {
  border-radius: 40px !important;
  border: 3px solid var(--cp-yellow, #EBE900) !important;
  min-width: 175px !important;
  min-height: 48px;
  flex-shrink: 0;

  color: #151718 !important;
  text-align: center !important;
  font-family: "Proxima_Nova", serif !important;
  font-size: 30px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  text-transform: none !important;

}

.contained-button {
  border-radius: 40px !important;
  background: var(--cp-yellow, #EBE900) !important;
  min-width: 175px !important;
  min-height: 48px;
  flex-shrink: 0;

  color: #151718 !important;
  text-align: center !important;
  font-family: "Proxima_Nova", serif !important;
  font-size: 30px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  text-transform: none !important;
  margin-right: 8px !important;
}

.contained-button2 {
  border-radius: 40px !important;
  background: var(--cp-yellow, #EBE900) !important;
  min-width: 175px !important;
  min-height: 48px;
  flex-shrink: 0;

  color: #151718 !important;
  text-align: center !important;
  font-family: "Proxima_Nova", serif !important;
  font-size: 30px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  text-transform: none !important;
}

/* scroolbar  */
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 7px;
  background: rgba(0, 125, 255, 0.20);
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 7px;
  background: var(--cp-blue, #007DFF);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--cp-blue, #007DFF);
}

.thank-note-p {
  color: #FFF;
  text-align: center;
  font-family: "Proxima_Nova", serif !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  max-width: 928px;
  width: 100%;
  margin: -10px auto;
}

.modal-bg {
  background-position: center center;
  background-size: 100% 100%;
  height: calc(100vh - 368px);
}

.modal-body-box {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  text-align: center;
  gap: 20px;
  padding: 4rem 15%;
}

.css-mhc70k-MuiGrid-root>.MuiGrid-item {
  padding-left: 0px !important;
}

.css-mhc70k-MuiGrid-root>.MuiGrid-item {
  padding-top: 0px !important;
}

.thank-note-head {
  color: #FFF;
  text-align: center;
  font-family: "Proxima_Nova", serif !important;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 157.5%;
  /* 47.25px */
  /* position: absolute;
  text-align: center;
  top: 255px; */
  width: 100%;
  /* margin: 0; */
}

/* .thank-note-button{
  position: absolute;
  text-align: center;
  top: 368px;
  left: 35%;
} */
.thank-note-img {
  /* position: absolute !important; */
  text-align: center !important;
  /* top: 310px !important; */
  /* left: 46% !important; */
  margin: auto;
}

.css-ihm285-JoyIconButton-root:disabled,
.css-q7t84n-JoyIconButton-root:disabled {
  background-color: var(--joy-palette-neutral-solidDisabledBg, var(--joy-palette-neutral-100, #5E5E5E)) !important;
  border: none !important;
  color: #00000085 !important;
}

.css-tj1jxg-JoyIconButton-root:disabled,
.css-1uofmu9-JoyIconButton-root:disabled {
  background-color: var(--joy-palette-neutral-solidDisabledBg, var(--joy-palette-neutral-100, #5E5E5E)) !important;
  border: none !important;
  color: #00000085 !important;
}

.css-1r1cqyb-JoyIconButton-root:disabled,
.css-vdulgp-JoyIconButton-root:disabled {
  background-color: var(--joy-palette-neutral-solidDisabledBg, var(--joy-palette-neutral-100, #5E5E5E)) !important;
  border: none !important;
  color: #00000085 !important;
}

.css-ihm285-JoyIconButton-root,
.css-q7t84n-JoyIconButton-root {
  background-color: var(--joy-palette-neutral-solidBg, var(--joy-palette-neutral-500, #0000)) !important;
}

.search-box {
  padding: 0px 10px 0px !important;
  position: sticky !important;
  /* width: 92%; */
  top: 0px;
  z-index: 999999;
  background: #fff !important;
  height: 64px;
  /* disable search hide animation  */
  opacity: 1 !important;
  pointer-events: unset !important;
  cursor: unset !important;
}

.search-box .inbox-search {
  width: 100%;
}

/* .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedStart.css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 24px;
  border: 1px solid #9A50FF;
  background: rgba(154, 80, 255, 0.10);
} */
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root,
.css-1bn53lx {
  border-radius: 24px !important;
  border: 1px solid var(--cp-blue, #007DFF) !important;
  background: rgba(0, 125, 255, 0.20) !important;
}

.css-6hp17o-MuiList-root-MuiMenu-list,
.css-r8u8y9 {
  /* padding-bottom: 0px !important; */
  width: 288px !important;
  height: 323px !important;
  overflow: auto !important;
  overflow-x: hidden !important;
  padding-right: 10px;
  padding-top: 0 !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiMenu-paper.MuiPopover-paper.MuiMenu-paper.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper,
.css-pwxzbm {
  border-radius: 30px !important;
  border: 1px solid var(--cp-blue, #007DFF) !important;
  background: #FFF !important;
  padding: 10px 0 !important;
  min-width: auto !important;
}

input#input-with-icon-textfield {
  color: #666;
  font-family: "Proxima_Nova", serif !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 10px 15px;
}

input#input-with-icon-textfield::-webkit-input-placeholder {
  text-transform: capitalize;
}

.search-icon {
  font-size: 30px !important;
  color: var(--cp-blue, #007DFF) !important;
}

.countries-item {
  color: #000 !important;
  font-family: "Proxima_Nova", serif !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  letter-spacing: -1.2px !important;
  margin: 0px 15px !important;
}

.drag-head {
  color: #151718 !important;
  text-align: center !important;
  font-family: "Proxima_Nova", serif !important;
  font-size: 32px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
}

.drag-sub-head {
  color: #696969 !important;
  text-align: center !important;
  font-family: "Proxima_Nova", serif !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  max-width: 220px;
  width: 100%;
  height: auto;
  margin: auto !important;
}

.drag-bold-head {
  color: #696969 !important;
  font-family: "Proxima_Nova", serif !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
}

.upload-progress {
  width: 244px !important;
  height: 244px !important;
  flex-shrink: 0 !important;
  stroke-width: 12px !important;
  stroke: var(--primary-pink, #007DFF) !important;
  color: var(--primary-pink, #007DFF) !important;
  /* border: 19px solid rgba(154, 80, 255, 0.40);
border-radius: 50%; */
  --CircularProgress-trackColor: var(--joy-palette-primary-solidBg, var(--joy-palette-primary-500, rgba(0, 125, 255, 0.40))) !important;
  --CircularProgress-progressColor: var(--joy-palette-primary-solidColor, var(--joy-palette-common-white, #007DFF)) !important;
  --_track-thickness: var(--CircularProgress-trackThickness, var(--CircularProgress-thickness, 10px)) !important;
  --_progress-thickness: var(--CircularProgress-progressThickness, var(--CircularProgress-thickness, 10px)) !important;
  --_root-size: var(--CircularProgress-size, 244px) !important;
  --CircularProgress-size: 244px !important;
}

/* .upload-progress-back{
  width: 220px !important;
height: 218px !important;
flex-shrink: 0 !important;
stroke-width: 12px !important;
stroke: rgba(154, 80, 255, 0.40) !important;
border: 10px solid rgba(154, 80, 255, 0.40);
border-radius: 50%;
} */

.percentage-progress {
  color: #FFF !important;
  text-align: center !important;
  font-family: "Proxima_Nova", serif !important;
  font-size: 60px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
}

.percentage-percent {
  color: #FFF !important;
  font-family: "Proxima_Nova", serif !important;
  font-size: 30px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  margin-bottom: -10px;
}

.fileupload {
  position: absolute;
  top: 20px;
  bottom: 82px;
  width: 92%;
  left: 26px;
  z-index: 9;
  right: 20px;
  opacity: 0;
  display: block !important;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .block1 {
    position: fixed;
    right: 15px;
    bottom: 64px;
    z-index: 9;
  }

  /* .block1 button{
      background-color: #222 !important;
    } */
  .mobile-icon2 {
    bottom: 129px;
    margin: 0 8px !important;
  }

  .mobile-icon1 {
    bottom: 175px !important;
    display: none;
  }

  .total-count-down {
    top: 30px !important;
  }

  /* change div positions  */
  .file-numbers {
    -webkit-box-ordinal-group: 3;
    -moz-box-ordinal-group: 3;
    -ms-flex-order: 3;
    -webkit-order: 3;
    order: 3;
    padding-top: 18px;
  }

  .block12 {
    -webkit-box-ordinal-group: 2;
    -moz-box-ordinal-group: 2;
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }

  .block2 {
    -webkit-box-ordinal-group: 1;
    -moz-box-ordinal-group: 1;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }

  /* .contained-button{
      margin-bottom: 10px !important;
    } */
  .thank-note-head {
    margin: 0;
  }

  .select_div {
    padding-bottom: 15px !important;
  }

  .email_div {
    padding-bottom: 15px !important;
  }

  .upload-progress {
    width: 160px !important;
    height: 160px !important;
    --_root-size: var(--CircularProgress-size, 160px) !important;
    --CircularProgress-size: 160px !important;
  }

  .percentage-progress {
    font-size: 40px !important;
  }

  .percentage-percent {
    font-size: 20px !important;
  }

  /* .total-count-down {
      left: 10px !important;
    } */
  .MuiModal-root {
    margin: 30px !important;
  }

  .insructions li {
    font-size: 16px;
  }

  .insructions h4 {
    font-size: 24px;
  }

  .sub-heading-one {
    font-size: 16px !important;
    line-height: 1.2 !important;
  }

  p.record-voice {
    font-size: 24px;
  }

  p.record-avl {
    font-size: 24px;
  }
  .textcontentcentralized{
    padding: 0 5% !important;
  }
}

.file-numbers {
  color: #FFF;
  font-family: "Proxima_Nova", serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-left: 40px;
}

.total-count-down {
  position: absolute;
  right: 30px;
  top: 18px;
  color: #FFF;
  font-family: "Proxima_Nova", serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.btn-submit:disabled {
  background-color: #5E5E5E !important;
  color: #FFF !important;
  cursor: auto;
}

@media screen and (max-width: 600px) {

  /* .thank-note-img{
    display: none;
  } */
  .thank-note-button {
    font-size: 18px !important;
  }

  .thank-note-head {
    font-size: 22px !important;
  }

  .thank-note-p {
    font-size: 18px !important;
  }

  /* .table-class{
    overflow-x: auto;
  } */
  .flex-box {
    margin-bottom: 0 !important;
  }
}

#waveform ::part(region-handle-left):after {
  /* content: '🏄'; */
  content: url(Assets/img/green.png);
  font-size: 1.5em;
  position: absolute;
  left: -1px;
  top: -27px;
  transform: translateX(-50%);
}

#waveform ::part(region-handle-right):before {
  /* content: '🏄'; */
  content: url(Assets/img/red.png);
  font-size: 1.5em;
  position: absolute;
  right: -13px;
  top: -27px;
  transform: translateX(-50%);
}

#waveform ::part(region-handle-left) {
  border-left-width: 2px !important;
  border-left-color: var(--bright-green, #5DFF00) !important;
  margin-top: -16px !important;
  height: 65px !important;
  display: none;
}

#waveform ::part(region-handle-right) {
  border-right-width: 2px !important;
  border-right-color: var(--red, #FF0505) !important;
  margin-top: -16px !important;
  height: 65px !important;
  display: none;
}

.emailerror {
  font-family: "Proxima_Nova", serif !important;
  color: red;
  font-size: large;
  padding: 0 15px;
}

#waveform ::part(scroll) {
  overflow-x: unset !important;
  overflow-y: unset !important;
}

#waveform ::part(region) {
  max-width: 100% !important;
  display: none;
}

.css-g6xhbl-MuiButtonBase-root-MuiMenuItem-root.Mui-focusVisible {
  background-color: #0000 !important;
}

.css-ja5taz-MuiTooltip-tooltip,
.css-1spb1s5,
.css-1l70ank-MuiTooltip-tooltip,
.css-uf3q9r {
  font-size: 18px !important;
  margin-bottom: 5px !important;
  border-radius: 25px !important;
}

/* .MuiTooltip-popper[data-popper-placement*="bottom"] .css-ja5taz-MuiTooltip-tooltip{
  margin-top: 0 !important;
} */
.playM {
  font-size: 24px !important;
}

.css-dwe8mg-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root:hover .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.css-dwe8mg-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.new_round_buttons {
  border-radius: 50% !important;
  font-family: 'Proxima_Nova', sans-serif !important;
  border: 3px solid var(--cp-yellow, #EBE900) !important;
  background: #222 !important;
  color: #FFF !important;
  width: 48px !important;
  height: 48px !important;
  font-size: 25px !important;
}

.new_round_buttons:disabled {
  border-radius: 50% !important;
  font-family: 'Proxima_Nova', sans-serif !important;
  border: none !important;
  background: #818181 !important;
  color: #222222 !important;
  width: 48px !important;
  height: 48px !important;
  font-size: 25px !important;
}

.new_round_buttons.countdown-btn:disabled {
  background: var(--cp-yellow-clicked, #EBE900) !important;
}

.new_round_buttons:hover {
  border-radius: 50% !important;
  font-family: 'Proxima_Nova', sans-serif !important;
  border: none !important;
  background: var(--cp-yellow-clicked, #EBE900) !important;
  color: #222222 !important;
  width: 48px !important;
  height: 48px !important;
  font-size: 25px !important;
}

.new_round_buttons:focus {
  border-radius: 50% !important;
  font-family: 'Proxima_Nova', sans-serif !important;
  border: none !important;
  background: var(--cp-yellow, #807E00) !important;
  color: #222222 !important;
  width: 48px !important;
  height: 48px !important;
  font-size: 25px !important;
}

.new_round_buttons_stop {
  border-radius: 50% !important;
  font-family: 'Proxima_Nova', sans-serif !important;
  /* border: 3px solid var(--cp-yellow, #EBE900) !important; */
  background: #222 !important;
  color: #FFF !important;
  /* width: 48px !important;
  height: 48px !important; */
  font-size: 25px !important;
}

.new_round_buttons_stop:disabled {
  color: #ffffff80 !important;
}

@media screen and (max-width: 615px) {
  .heading1 {
    padding: 0 0px 0 10px;
    width: 72px;
    font-size: 16px;
  }

  .heading1 span,
  .heading2 span {
    padding-left: 10px;
  }

  .heading2 {
    padding: 0 0px 0 10px;
    font-size: 16px;
    text-align: left;
  }

  .heading3country {
    max-width: 90px;
  }

  .leaderboard-h {
    width: 350px;
  }

  .leaderboard-sb {
    width: 350px;
  }

  .table-class {
    max-width: 350px;
    width: 100%;
    height: auto;
    margin: auto;
    text-align: -webkit-center;
  }

  .new_round_buttons {
    background: var(--cp-yellow-clicked, #EBE900) !important;
    color: #222222 !important;
    width: 56px !important;
    height: 56px !important;
    font-size: 30px !important;
  }

  .new_round_buttons svg {
    font-size: 30px !important;
  }

  .new_round_buttons:disabled {
    width: 56px !important;
    height: 56px !important;
    font-size: 30px !important;
  }

  .new_round_buttons:hover {
    background: var(--cp-yellow-clicked, #EBE900) !important;
    color: #222222 !important;
    width: 56px !important;
    height: 56px !important;
    font-size: 30px !important;
  }

  .new_round_buttons:focus {
    width: 56px !important;
    height: 56px !important;
    font-size: 30px !important;
  }

  /* .new_round_buttons_stop{
    background: var(--cp-yellow-clicked, #EBE900) !important;
    color: #222222 !important;
    width: 56px !important;
    height: 56px !important;
    font-size: 30px !important;
  } */
  .css-1nv95zm-JoyCircularProgress-root {
    --_root-size: var(--CircularProgress-size, 56px) !important;
    --CircularProgress-size: 56px !important;
  }

  .joy_progress {
    --_root-size: var(--CircularProgress-size, 56px) !important;
    --CircularProgress-size: 56px !important;
    width: 56px !important;
    height: 56px !important;
  }
}

.sub-heading-one {
  color: #FFF;
  text-align: center;
  font-family: 'Proxima_Nova', sans-serif !important;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 15.6px;
  padding-left: 2%;
}

.one-world {
  font-family: "EB_Garamond" !important;
  font-size: 115px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: linear-gradient(90deg, #CF3B0A 0%, #C89B0F 16.15%, #53A52F 43.23%, #0760C2 70.31%, #7A3DAA 82.67%, #C52782 93.88%, #CB1856 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.css-igs3ac {
  border: none !important;
}

.align_country {
  display: flex;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root,
.css-1km1ehz {
  white-space: unset !important;
}

.news_checkbox .MuiTypography-root {
  color: rgba(0, 0, 0, 0.63) !important;
  font-family: 'Proxima_Nova', sans-serif !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  padding-left: 10px;
  /* line-height: normal !important; */
}

.news_checkbox .MuiSvgIcon-root {
  font-size: 24px !important;
}

.heading_news {
  color: #151718;
  text-align: center;
  font-family: 'Proxima_Nova', sans-serif !important;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.text-layout-privacy1 {
  padding-left: 3%;
  padding-bottom: 25px;
}

.album_booklet input,
.subscribe_input {
  border-radius: 50px;
  border: 1.5px solid #C5C5C5;
  color: #000;
  width: -webkit-fill-available;
  width: -moz-available;
  padding: 0 15px;
  height: 40px;
}

.album_booklet input::placeholder {
  font-family: "Proxima_Nova", serif !important;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.subscribe_input {
  border-radius: 50px !important;
  border: 1.5px solid #C5C5C5 !important;
  color: #000 !important;
  width: -webkit-fill-available !important;
  padding: 0 15px !important;
  height: 40px !important;
  font-size: 22px;
  margin: 10px;
}

.subscribe_input:focus-visible {
  outline: none;
}

.subscribe_input::placeholder {
  font-family: "Proxima_Nova", serif !important;
  font-size: 22px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  color: #A7A7A7;
}

.subscribe_sub {
  color: #222 !important;
  font-family: "Proxima_Nova", serif !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  margin: 10px !important;
  text-align: center;
}

.subscribe_head {
  color: #151718 !important;
  font-family: "Proxima_Nova", serif !important;
  font-size: 30px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  text-transform: uppercase !important;
  text-align: center;
  padding-top: 30px !important;
}

.check_uncheck_position {
  position: relative;
  top: 4px;
}

.btn_classy:disabled {
  background-color: #807E00 !important;
}

.news_checkbox {
  margin-bottom: 10px;
  align-items: baseline !important;
}

.terms_text_grey {
  /* color: #A7A7A7; */
  font-family: "Proxima_Nova", serif !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.album_booklet_terms {
  padding: 0 20px !important;
  margin-bottom: 30px;
}

.privacy-title-accept {
  color: #151718 !important;
  font-family: "Proxima_Nova", serif !important;
  text-align: center !important;
  margin-top: 20px !important;
  font-size: 30px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  text-transform: uppercase !important;
}

.firstNameGrid {
  padding-right: 0 !important;
}

.emailGrid {
  padding-top: 0 !important;
}

.introduction_top {
  color: #FFF;
  font-family: "Proxima_Nova", serif !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 140% !important;
  /* 33.6px */
}

.introduction_right {
  color: var(--Mid-Blue, #007DFF);
  font-family: "Proxima_Nova", serif !important;
  font-size: 24px !important;
  font-style: italic !important;
  font-weight: 400 !important;
  line-height: 140% !important;
  float: right;
}

@media screen and (max-width: 600px) {
  .secondNameGrid {
    padding-top: 0 !important;
  }

  .firstNameGrid {
    padding-right: calc(var(--Grid-columnSpacing) / 2) !important;
  }
}

@media screen and (max-width: 767px) {
  .privacy-title-accept {
    font-size: 20px !important;
  }

  .news_checkbox .MuiTypography-root {
    font-size: 16px !important;
  }

  .contained-button {
    min-width: 75px !important;
    min-height: 38px;
    font-size: 20px !important;
  }

  .outlined-button {
    min-width: 75px !important;
    min-height: 38px;
    font-size: 20px !important;
  }

  .text-layout-privacy1 {
    padding-bottom: 0;
    /* overflow-y: auto !important;
    max-height: 49vh;
    overflow-x: hidden; */
  }

  .drag-head {
    font-size: 20px !important;
  }

  .btn {
    font-size: 18px;
  }

  .accept_modal {
    padding: 0 !important;
  }

  .check_uncheck_position {
    width: 20px;
    height: 20px;
  }

  .introduction_top {
    font-size: 16px !important;
  }

  .introduction_right {
    font-size: 16px !important;
  }

  .subscribe_input {
    font-size: 18px;
  }

  .subscribe_input::placeholder {
    font-size: 18px !important;
  }

  .subscribe_sub {
    font-size: 18px !important;
  }

  .subscribe_head {
    font-size: 24px !important;
  }
}

.coldplay_redirect {
  color: #FFF;
  font-family: "Proxima_Nova", serif !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -1.2px;
  margin-bottom: 40px;
}

.coldplay_redirect_link {
  color: var(--Yellow, #EBE900);
}

.coldplay_footer_links {
  color: var(--White, #FFF) !important;
  font-family: "Proxima_Nova", serif !important;
  font-size: 9px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  letter-spacing: 7px !important;
}

.coldplay_footer_redirect_link {
  /* color: var(--Dark-Blue, #0061AA) !important; */
  color: var(--White, #FFF) !important;
  text-align: center !important;
  font-size: 9px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.45px;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  .coldplay_redirect {
    font-size: 18px;
  }
}

#close-pc-btn-handler {
  display: none !important;
}

.new_round_buttons.countdown-btn.count_down_disabled:disabled {
  background: #818181 !important;
}