.App {
  text-align: center;
}

.App-logo {
  max-width: 100%;
  /* pointer-events: none; */
  /* margin: 4rem 0 2rem 0; */
  /* width: min(41.25vw, 594px); */
}
.sub-heading {
  font-size: 32px;
  font-weight: 700;
  font-family: "EB_Garamond";
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  display: inline-block;
  padding: 2px 12px;
  letter-spacing: 1px;
  margin: 40px 0;

  color: #F7F8FA;
text-align: center;
font-style: normal;
line-height: normal;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.title{
  color: #ffffff !important;
}

